<template>
  <div>
    <div v-if="files.length == 0">No Files</div>
    <div v-else style="display: flex; gap: 10px; flex-wrap: wrap;">
      <div v-for="file in files" :key="file.fileId"
        class="preview-file-button"
        @click="previewFileMixin(file.fileId)"
      >
        {{ file.fileDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
export default {
  name: 'JobFiles',
  props: {
    jobFiles: Object
  },
  mixins: [baseMixin],
  data () {
    return {

    }
  },
  computed: {
    files () {
      const result = []
      if (this.jobFiles.coqFileId) result.push({ fileId: this.jobFiles.coqFileId, fileDescription: 'COQ' })
      if (this.jobFiles.cqFileId) result.push({ fileId: this.jobFiles.cqFileId, fileDescription: 'CQ' })
      if (this.jobFiles.bmtFileId) result.push({ fileId: this.jobFiles.bmtFileId, fileDescription: 'Bunker Metering Ticket' })
      if (this.jobFiles.beforeDeliveryFileId) result.push({ fileId: this.jobFiles.beforeDeliveryFileId, fileDescription: 'Pre-Delivery' })
      if (this.jobFiles.afterDeliveryFullSetFileId) result.push({ fileId: this.jobFiles.afterDeliveryFullSetFileId, fileDescription: 'Full Set' })
      if (this.jobFiles.afterDeliverySummaryFileId && this.jobFiles.afterDeliverySummaryFileId?.length>0 ){
        for (let i = 0; i < this.jobFiles.afterDeliverySummaryFileId.length; i++) {
          let name ='Summary '
          if (this.jobFiles.afterDeliverySummaryFileId.length>1) { name += i+1 }
          result.push({ fileId: this.jobFiles.afterDeliverySummaryFileId[i], fileDescription: `${name}`})
        }
      }

      if (this.jobFiles.additionalDocuments) {
        this.jobFiles.additionalDocuments.forEach(doc => result.push({ fileId: doc.fileId, fileDescription: doc.fileDescription }))
      }
      return result
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.preview-file-button {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #0d6efd;
  border-color: #0d6efd;
}
.preview-file-button:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
</style>
